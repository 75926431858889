.container_2videos {
  color: var(--mainWhite);
  width: 70%;
  margin: 0 auto;
  overflow: hidden;
}
.CategoryItem_main_info_title {
  color: var(--brand);
  line-height: 28px;
  margin: 0px 10px;
}
.CategoryItem_main_info_title {
  padding: 30px 0px;
}

.Events_main {
  /* margin: 30px auto; */
}
.Events_main h2 {
  color: var(--brand);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
}
.Events_main_info {
  display: flex;
  align-items: center;
  padding: 10px 0px;
  justify-content: space-between;
  gap: 10px;
}

.Events_main_info a {
  height: 100%;
  border: none;
  color: var(--brand);
  text-decoration: none;
}
.Events_main_info a:hover {
  cursor: pointer;
  color: var(--brandHover);
  transition: all ease 0.4s;
}

.Events_main_info h2 {
  padding: 10px 30px;
}

.Events_main h2::after {
  display: none;
}
.container_3videos {
  color: var(--mainWhite);
  width: 70%;
  margin: 0 auto;
  overflow: hidden;
}
.container_homePage {
  width: 95%;
}
.EventTitle {
  color: var(--brandHover);
}

.Events {
  background-color: white;
  border-radius: 10px;
}
.Events:hover {
  cursor: pointer;
}
.Event {
  display: flex;
  align-items: center;
  flex-direction: column;
  color: var(--brand);
  padding-bottom: 40px;
  height: 200px;
  max-height: 200px;
  min-height: 200px;
}
.EventTitle {
  padding-bottom: 10px;
}

.EventInfo {
  color: var(--mainYellow);
  padding-bottom: 20px;
}
.EventDuration {
  margin-top: 10px;
}
.EventInfo,
.EventTitle,
.EventDuration {
  width: 90%;
  margin: auto;
  display: flex;
  align-items: center;
}

.EventInfo > * + * {
  margin-left: 10px !important;
}
.EventTitle > * + * {
  margin-left: 10px !important;
}
.EventDuration > * + * {
  margin-left: 10px !important;
}
.EventTitle:hover {
  text-decoration: underline;
  color: var(--brand);
}
.icon {
}

.event {
  width: 80%;
  margin: 0 auto;
  padding-top: 30px;
}
.event h2 {
  color: var(--mainWhite);
  padding-bottom: 0;
}
.iframe {
  /* height: 80vh; */
  border: none;
  margin: 10px 0;
}

.ViewedSectionSecondary__2videos {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(350px, 100%), 300px));
  align-items: center;
  column-gap: 30px;
  row-gap: 20px;
}
.ViewedSectionSecondary__3videos {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(300px, 100%), 350px));
  align-items: center;
  column-gap: 30px;
  row-gap: 20px;
  /* justify-content: center; */
}
.NoDataAvailable {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  padding: 10px 0 30px 0;
}

.NoDataAvailable h3 {
  color: var(--brand);
}
.NoDataAvailable img {
  width: 200px;
}

.modalItem {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
}
.modalItem_button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
}
.modalItem_button button {
}
.modalItem_button_confirm {
  background-color: var(--brand);
  color: var(--mainWhite);
}
.modalItem_button_confirm:hover {
  background-color: var(--brandHover);
  color: var(--mainWhite);
}
.modalItem_button_cancel:hover {
  background-color: var(--red);
  color: var(--mainWhite);
}
.Events_main_info_filter_addFilter,
.Events_main_info_filter_removeFilter {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.Events_main_info_filter_addFilter {
  background-color: var(--brand);
  color: var(--mainWhite);
}
.Events_main_info_filter_addFilter:hover .filterIcon {
  background-color: var(--mainYellow);
  color: var(--mainWhite);
}
.Events_main_info_filter_addFilter:hover {
  background-color: var(--brandHover);

  color: var(--mainWhite);
}
.Events_main_info_filter_removeFilter:hover .removeIcon {
  background-color: var(--red);
  color: var(--mainWhite);
}
.Events_main_info_filter_removeFilter:hover {
  background-color: var(--red);
  color: var(--mainWhite);
}
/* filter icon */
.filterIcon {
  color: var(--mainYellow);
}
.filterIcon:hover {
  color: var(--mainWhite);
  cursor: pointer;
}
.removeIcon {
  color: var(--red);
}

.Events_main_info_button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.Events_main_info_header {
  display: flex;
  flex-direction: column;
}
@media (max-width: 1050px) {
  .container_2videos {
    width: 100%;
  }
}
@media (max-width: 850px) {
  .ViewedSectionSecondary__2videos {
    grid-template-columns: repeat(auto-fit, minmax(min(300px, 100%), 300px));
  }
  .container_2videos {
    width: 100%;
  }
  .Events_main_info h2 {
    padding: 20px 10px;
  }
  .NoDataAvailable img {
    width: 300px;
  }
}
@media (max-width: 650px) {
  .container_2videos {
    width: 80%;
    margin: 0 auto;
  }
  .NoDataAvailable img {
    width: 200px;
  }
}
