.EventPrimary {
  width: 80%;
  margin: 0 auto;
  background-color: var(--mainContainerBackground);
  color: var(--mainBlack);
}
.EventPrimary > * {
  padding-bottom: 10px;
}

.EventPrimary_title {
  padding: 50px 0 20px 0;
}
h1::after,
h2::after {
  display: none;
}
.EventPrimary_countdown {
  /* font-size: 2.5rem; */
}
.EventPrimary_countdown {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.EventPrimary_countdown > * + * {
  margin-left: 20px;
}

.EventPrimary_countdown span {
  padding: 20px;
  background-color: var(--brand);
  color: var(--mainWhite);
}
.EventPrimary_countdown_description {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.EventPrimary_countdown_description > * + * {
  margin-top: 10px;
}

.EventPrimary_countdown_description p,
.EventPrimary_date,
.EventPrimary_time {
  /* font-size: 1.3rem; */
}

.EventPrimary_date,
.EventPrimary_time {
  display: flex;
  margin-top: 10px;
  align-items: center;
}
.EventPrimary_date > * + * {
  margin-left: 20px;
}
.EventPrimary_time > * + * {
  margin-left: 20px;
}
.EventPrimary_date {
  margin-top: 50px;
}
.EventPrimary_time {
  margin-bottom: 60px;
}
.EventPrimary_description > * {
  margin: 30px 0;
  line-height: 200%;
}
.EventPrimary_description span,
.EventPrimary_description p,
.EventPrimary_description h1 h2 h3 {
  background-color: #f3f4f8 !important;
  color: var(--brand) !important;
}
.EventPrimary_description a {
  text-decoration: none;
  color: var(--brand);
}
.EventPrimary_description a:hover {
  text-decoration: underline;
  color: var(--brandHover);
}
.EventPrimary_description p {
  font-size: 1.1rem;
}
