.CategoryItem_main {
  /* margin: 30px auto; */
  margin-bottom: -30px;
  /* width: 90%; */
}
.CategoryItem_main_info {
  display: flex;
  align-items: center;
  padding: 10px 0px;
  justify-content: space-between;
  width: 100%;
}

.CategoryItem_main_link {
  height: 100%;
  border: none;
  color: var(--mainCategoryViewAll);
  text-decoration: none;
  font-weight: 400;
  line-height: 140%;
  transition: 0.3s ease;
  margin-right: 5px;
}

.CategoryItem_main_link:hover {
  cursor: pointer;
  color: var(--mainCategoryViewAllHover);
}

.CategoryItem_main_info_title {
  color: var(--mainSliderCategoryTitle);
  line-height: 28px;
  margin: 0px 5px;
}

.CategoryItem_main_info_title,
.CategoryItem_main_info button {
  padding: 30px 0px;
}
