.chosenCategoryGrid {
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-bottom: 5%;
}

.ViewedSectionSecondary__3videos {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(350px, 100%), 350px));
  /* align-items: center; */
  justify-content: center;
  column-gap: 30px;
  row-gap: 20px;
}

/* media Queeries */
@media (max-width: 950px) {
  .chosenCategoryGrid {
    width: 100%;
    margin: 0 10px;
  }
}
@media (max-width: 750px) {
  .ViewedSectionSecondary__3videos {
    grid-template-columns: repeat(auto-fit, minmax(min(250px, 100%), 350px));
  }
}
@media (max-width: 550px) {
  .chosenCategoryGrid {
    width: 80%;
    margin: 0 auto;
  }
  .ViewedSectionSecondary__3videos {
    grid-template-columns: repeat(auto-fit, minmax(min(250px, 100%), 1fr));
  }
}
