.primarycontainer_Promo {
  margin-top: 20px;
  /* padding: 10px 50px 50px 50px; */
}
.secondaryContainer {
  margin: auto;
  color: var(--mainWhite);
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
}
.container_multipleItem {
  display: grid;

  grid-template-columns: repeat(auto-fit, minmax(min(300px, 100%), 250px));
  /*align-items: center;*/
  column-gap: 20px;
  row-gap: 20px;
  justify-content: center;
}
@media (max-width: 1441px) {
  .container_multipleItem {
    display: grid;

    grid-template-columns: repeat(auto-fit, minmax(min(300px, 100%), 250px));
    /*align-items: center;*/
    column-gap: 20px;
    row-gap: 20px;
    justify-content: space-between;
  }
}

@media (max-width: 1200px) {
  .container_multipleItem {
    grid-template-columns: repeat(auto-fit, minmax(min(200px, 100%), 200px));
  }
}
@media (max-width: 1000px) {
  .container_multipleItem {
    justify-content: space-around;
  }
  .container_multipleItem {
    grid-template-columns: repeat(auto-fit, minmax(min(300px, 100%), 250px));
  }
}
@media (max-width: 550px) {
  .container_multipleItem {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(min(200px, 100%), 1fr));
    /*align-items: center;*/
    column-gap: 30px;
    row-gap: 20px;
    justify-content: center;
  }
}
